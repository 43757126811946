// Environment
import { environment } from "@environment";
// Lodash
import { toUpper } from "lodash";

export const APP_CONSTANT = {
    DFAULT_LOCALE: environment.visitorSettings.defaultLocale,
    AVIALABLE_LANGS: ['sv-se', 'en-se', 'is-is', 'fi-fi', 'da-dk', 'nb-no', 'es-es', 'fr-fr', 'it-it', 'de-de'],
    JWT_TOKEN_KEY: 'perfect_jwt_auth_v2',
    LOGGED_USER: 'logged_user_v2',
    ERROR_PATH: '/error/404',
    USER_LOCALE_KEY: 'user_locale',
    USER_LANGUAGE_KEY: 'language',
    PUSH_NOTIFICATION_PUBLIC_KEY: 'BLBx-hf2WrL2qEa0qKb-aCJbcxEvyn62GDTyyP9KTS5K7ZL0K7TfmOKSPqp8vQF0DaG8hpSBknz_x3qf5F4iEFo',
    ACTIVE_CALL_SESSION_KEY: 'infracom',
    SELLER_PHONE_CALL: 'seller_phone_call_'
};

export const GOOGLE_MAP_URL: string = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapKey}&libraries=places,geometry`;
export const LOCALHOST_URL: string = 'https://localhost:4200';
export const SAVE_PART_LS_KEY = 'saved-parts';
export const CART_PART_LS_KEY = 'cart-parts';
export const PART_LANG_HREF_REDIRECTS_PATHS: Array<string> = ['/pb/', '/ov/', '/df/', '/vs/', '/OEM/', '/part-item/'];

// PLP Default sorting
export const PLP_DEAFULT_SORT_COLUMN: string = environment.defaultSort.column;
export const PLP_DEAFULT_SORT_DIRECTION: string = environment.defaultSort.direction;

// Application Origin
const orgID: string = toUpper(environment.orgId);
export const ORIGIN_SUBJECT: string = `${orgID}/PLP`;
export const ORIGIN_ITEM: string = `${orgID}/PDP`;
export const ORIGIN_SAVED_PARTS: string = `${orgID}/SAVED_PARTS`;
export const ORIGIN_QUICK_CART: string = `${orgID}/QUICK_CART`;
export const ORIGIN_VS_SUBJECT: string = `${orgID}/VLP`;
export const ORIGIN_VS_ITEM: string = `${orgID}/VDP`;
export const ORIGIN_FILTER: string = `${orgID}/FILTER`;
export const ORIGIN_DF_SUBJECT: string = `${orgID}/TRLP`;
export const ORIGIN_DF_FILTER: string = `${orgID}/TR_FILTER`;

// Ngrx Store
export const STORE = {
    REGNR_LS_KEY: `_regnr_v1`, // change the version when you update the regcountries list in env file for domains
    FTS_LS_KEY: '_fts_'
}

export const MAT_DIALOG_OPTIONS = {
    hasBackdrop: true,
    panelClass: '',
    height: 'auto',
    width: '900px',
    autoFocus: false,
    maxHeight: '90vh'
};

export const COUNTRIES = {
    752: {
        locale: 'sv-se',
        country: 'Sweden',
        language: 'Svenska',
        flag: './assets/media/flags/sweden.svg'
    },
    578: {
        locale: 'nb-no',
        country: 'Norway',
        language: 'Norsk',
        flag: './assets/media/flags/norway.svg'
    },
    840: {
        locale: 'en-se',
        country: 'England',
        language: 'English',
        flag: './assets/media/flags/united-states.svg'
    },
    724: {
        locale: 'es-es',
        country: 'Spain',
        language: 'Español',
        flag: './assets/media/flags/spain.svg'
    },
    208: {
        locale: 'da-dk',
        country: 'Denmark',
        language: 'Dansk',
        flag: './assets/media/flags/denmark.svg'
    },
    352: {
        locale: 'is-is',
        country: 'Iceland',
        language: 'Íslensku',
        flag: './assets/media/flags/iceland.svg'
    },
    246: {
        locale: 'fi-fi',
        country: 'Finland',
        language: 'Finska',
        flag: './assets/media/flags/finland.svg'
    },
    250: {
        locale: 'fr-fr',
        country: 'France',
        language: 'French',
        flag: './assets/media/flags/france.svg'
    },
    380: {
        locale: 'it-it',
        country: 'Italy',
        language: 'Italian',
        flag: './assets/media/flags/italy.svg'
    }
};
export const LOCALES = [
    {
        id: 752,
        locale: 'sv-se',
        code: 'SWE',
        country: 'Sweden',
        language: 'Svenska',
        flag: './assets/media/flags/sweden.svg'
    },
    {
        id: 578,
        locale: 'nb-no',
        code: 'NOR',
        country: 'Norway',
        language: 'Norsk',
        flag: './assets/media/flags/norway.svg'
    },
    {
        id: 840,
        locale: 'en-se',
        country: 'USA',
        code: 'ENG',
        language: 'English',
        flag: './assets/media/flags/usa.svg'
    },
    {
        id: 724,
        locale: 'es-es',
        code: 'ESP',
        country: 'Spain',
        language: 'Español',
        flag: './assets/media/flags/spain.svg'
    },
    {
        id: 208,
        locale: 'da-dk',
        code: 'DNK',
        country: 'Denmark',
        language: 'Dansk',
        flag: './assets/media/flags/denmark.svg'
    },
    {
        id: 352,
        locale: 'is-is',
        code: 'ISL',
        country: 'Iceland',
        language: 'Íslensku',
        flag: './assets/media/flags/iceland.svg'
    },
    {
        id: 246,
        locale: 'fi-fi',
        code: 'FIN',
        country: 'Finland',
        language: 'Finska',
        flag: './assets/media/flags/finland.svg'
    },
    {
        id: 250,
        locale: 'fr-fr',
        code: 'FRA',
        country: 'France',
        language: 'French',
        flag: './assets/media/flags/france.svg'
    },
    {
        id: 380,
        locale: 'it-it',
        code: 'ITA',
        country: 'Italy',
        language: 'Italian',
        flag: './assets/media/flags/italy.svg'
    },
    {
        id: 276,
        locale: 'de-de',
        code: 'DEU',
        country: 'Germany',
        language: 'German',
        flag: './assets/media/flags/germany.svg'
    }
];
export const COUNTRY_CURRENCIES = [
    {
        symbol: 'SEK',
        label: 'Swedish krona',
        flag: './assets/media/flags/sweden.svg'
    },
    {
        symbol: 'NOK',
        label: 'Norwegian krone',
        flag: './assets/media/flags/norway.svg'
    },
    {
        symbol: 'USD',
        label: 'United State dollar',
        flag: './assets/media/flags/usa.svg'
    },
    {
        symbol: 'DKK',
        label: 'Danish krone',
        flag: './assets/media/flags/denmark.svg'
    },
    {
        symbol: 'ISK',
        label: 'Icelandic krona',
        flag: './assets/media/flags/iceland.svg'
    },
    {
        symbol: 'EUR',
        label: 'Euro',
        flag: './assets/media/flags/european-union.svg'
    }
];
export const TIME_ZONES = [
    { id: 1, code: 'Europe/Stockholm', label: 'Europe / Stockholm' },
    { id: 2, code: 'Europe/Helsinki', label: 'Europe / Helsinki' },
    { id: 3, code: 'Europe/Warsaw', label: 'Europe / Warsaw' },
    { id: 4, code: 'Atlantic/Reykjavik', label: 'Atlantic / Reykjavik' }
];
export const ORDER_SITE_IDS = {
    1: 'Bildelsbasen',
    5: 'Varaosahaku',
    90: 'Kalkyler',
    91: 'Kalkyler',
    92: 'Kalkyler',
    93: 'Kalkyler'
};
export const SHIPPING_PROVIDER_IDS = {
    0: 'Open provider',
    1: 'Pick up at location',
    2: 'Courier van',
    3: 'Posten',
    4: 'DHL ServicePoint',
    5: 'Bussgods',
    6: 'Schenker',
    7: 'DPD',
    9: 'DHL',
    10: 'TNT Express',
    11: 'BestXpress',
    12: 'Posti',
    13: 'Matkahuolto - BussiPaketti',
    14: 'Transpoint',
    15: 'Matkahuolto',
    16: 'Kaukokiito',
    17: 'UPS',
    18: 'Colleague only',
    19: 'Bring / Posti (VOH.fi)',
    20: 'Bring',
    21: 'The recipient pays'
};
export const ORER_TIME_ID = {
    1: '1-3 days',
    2: '4-8 days',
    3: '1 week',
    4: 'Not in stock',
    5: 'Demounted when sold'
};
export const PAYMENT_PROVIDER_IDS = {
    1: 'Dibs',
    7: 'Cash On Delivery',
    8: 'Invoice issued by dismantler',
    10: 'Bank payment',
    11: 'Bank payment',
    12: 'PayTrail',
    13: 'Invoice issued by Fortus',
    15: 'Payson',
    17: 'Swish - Företag',
    18: 'Swish - Handel',
    19: 'Nets'
};
export const PART_POSITION_BITS = {
    1: 'Inner',
    2: 'Outer',
    3: 'Right',
    4: 'Left',
    5: 'Front',
    6: 'Back',
    7: 'Upper',
    8: 'Lower',
    9: 'Center'
};
export const ORDER_STATES = {
    1: {
        label: 'Inquiry',
        color: 'metal',
        icon: 'flaticon-list'
    },
    2: {
        label: 'Ordered',
        color: 'primary',
        icon: 'flaticon-open-box'
    },
    3: {
        label: 'Confirmed',
        color: 'success',
        icon: 'flaticon-business'
    },
    4: {
        label: 'Delivered',
        color: 'dark',
        icon: 'flaticon-truck'
    },
    5: {
        label: 'Canceled',
        color: 'danger',
        icon: 'flaticon-cancel'
    },
    6: {
        label: 'Awaits payment',
        color: 'info',
        icon: 'flaticon2-reload'
    },
    7: {
        label: 'Reserved',
        color: 'warning',
        icon: 'flaticon-menu-button'
    },
    8: {
        label: 'Return requested',
        color: 'metal',
        icon: 'flaticon2-time'
    },
    9: {
        label: 'Return accepted',
        color: 'metal',
        icon: 'flaticon2-reply'
    },
    12: {
        label: 'Quality check',
        color: 'metal',
        icon: 'flaticon-list'
    },
    13: {
        label: 'Pending delivery',
        color: 'secondary',
        icon: 'flaticon2-delivery-truck'
    },
    14: {
        label: 'Processing',
        color: 'info',
        icon: 'flaticon-rocket'
    }
};
export const IMAGE_BASE_URL = environment.baseUrl;
export const CATEGORY_MAIN_IMG = 'assets/media/category-images/base.png';
// Reg No countries
export const REGNO_COUNTRIES = environment.regnrCountries;
export const FREE_SEARCH_OPTIONS = [
    {
        label: 'label_oem_number',
        value: 8
    },
    {
        label: 'label_engine_code',
        value: 6
    },
    {
        label: 'label_gearbox_code',
        value: 7
    },
    {
        label: 'label_part_item',
        value: 11
    }
];

// Other Brand Categories Map
export const BARND_OTHER_CATEGORIES: { [key: string]: string[] } = {
    '103': ['Entreprenad'],
    '104': ['Husvagn/Husbil', 'Husbil', 'Husvagn'],
    '105': ['MC'],
    '106': ['Snöskoter'],
    '107': ['Lastbil'],
    '108': ['Traktor'],
    '111': ['EU-Moped'],
    '112': ['ATV'],
    '113': ['Buss']
};
export const ENV_CUSTOMER_TYPE = 'environment-customer-type';
export const ENV_CUSTOMER_LANG = 'environment-customer-language';
export const ENV_CUSTOMER_LANG_CODE = 'environment-customer-language-code';
export const ENV_CUSTOMER_LANG_NAME = 'environment-customer-language-name';
export const ENV_CUSTOMER_COUNTRY_CODE = 'environment-customer-country-code';
export const ENV_CUSTOMER_COUNTRY_ID = 'environment-customer-country-id';
export const ENV_CUSTOMER_COUNTRY_NAME = 'environment-customer-country-name';
export const ENV_CUSTOMER_CURRENCY = 'environment-customer-currency';

export const TYRE_WIDTH = [135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 285, 295, 305, 315, 325, 335, 345, 355, 365];
export const TYRE_ASPECT_RATIO = [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
export const TYRE_WHEEL_DIAMETER_INCHES = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
// Angular
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Constants
import { APP_CONSTANT } from '@constants';
// Service
import { CommonService } from './common.service';

@Injectable()
export class InterceptService implements HttpInterceptor {

  /**
   * Creates an instance of InterceptService.
   * @param {CommonService} cs
   * @param {*} ssrHttpRequest
   * @memberof InterceptService
   */
  constructor(private readonly cs: CommonService,
    @Optional() @Inject('REQUEST') private readonly ssrHttpRequest: any) { }

  /**
   * Intercept request and add token
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof InterceptService
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Sent client IP address
    const CLIENT_IP_ADDRESS =
      this.ssrHttpRequest?.headers['x-real-ip'] ||
      this.ssrHttpRequest?.headers['x-forwarded-for'] ||
      this.ssrHttpRequest?.connection?.remoteAddress ||
      this.ssrHttpRequest?.socket.remoteAddress;

    if (CLIENT_IP_ADDRESS) {
      request = request.clone({ headers: request.headers.set('x-forwarded-for', CLIENT_IP_ADDRESS) });
    }

    // Add JWT Authorization token to each request after login
    const jwt = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
    if (jwt) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${jwt}`) });
    }

    // Return the modified request
    return next.handle(request);
  }
}

// Angular
import { Injectable, OnDestroy } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
// RxJS
import { Observable, Subject } from 'rxjs';
import { takeUntil, catchError, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/index';
import { isLoggedIn } from '../_selectors/auth.selectors';
// Services
import { GlobalErrorHandler } from '@core/utils';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class LoggedInAuthGuard implements CanActivate, OnDestroy {
    private unsubscribe = new Subject<void>();

    /**
     * LoggedInAuthGuard constructor
     *
     * @param store: Store<AppState>
     * @param router: Router
     * @param globalErrorHandler: GlobalErrorHandler
     * @param translocoService: TranslocoService
     */
    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly globalErrorHandler: GlobalErrorHandler,
        private readonly translocoService: TranslocoService,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(isLoggedIn),
            map((isLoggedIn) => {
                if (isLoggedIn) this.router.navigateByUrl(`/${this.translocoService.getActiveLang()}`);
                return !isLoggedIn;
            }),
            catchError(error => this.globalErrorHandler.handleError('logged-in-auth-guard', error)),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * On Destroy
     * @summary To avoid memory leak destroy component subscription
     */
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}

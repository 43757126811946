// Angular
import { LOCALE_ID, StaticClassProvider } from '@angular/core';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

class DynamicLocaleId extends String {
    constructor(protected service: TranslocoService) {
        super();
    }
    override toString() {
        return this.service.getActiveLang();
    }
}
export const LocaleProvider: StaticClassProvider = {
    provide: LOCALE_ID,
    useClass: DynamicLocaleId,
    deps: [TranslocoService]
};
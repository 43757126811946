// Angular
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

/**
 * StandardUrlSerializer
 * The brackets are automatically encoded, when the path parameter is contains parentheses
 * @export
 * @class StandardUrlSerializer
 * @implements {UrlSerializer}
 */
export class StandardUrlSerializer implements UrlSerializer {

    // Angular Default URL Serializer
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    /**
     * Parse the current URL
     * @param {string} url
     * @returns {UrlTree}
     * @memberof StandardUrlSerializer
     */
    parse(url: string): UrlTree {
        url = url.replace(/\(/g, "%28").replace(/\)/g, "%29");
        return this._defaultUrlSerializer.parse(url);
    }

    /**
     * Serialize method
     * @param {UrlTree} tree
     * @returns {string}
     * @memberof StandardUrlSerializer
     */
    serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer.serialize(tree);
    }
}
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Theme modes
export type ThemeModeType = 'dark' | 'light' | 'system';

// Theme mode key for localstorege
const THEME_MODE_LS_KEY = 'BDB_theme_mode_value';

const getThemeModeFromLocalStorage = (lsKey: string, platformId: Object): ThemeModeType => {
  if (isPlatformBrowser(platformId)) {
    if (!localStorage) {
      return 'light';
    }

    const data = localStorage.getItem(lsKey);
    if (!data) {
      return 'light';
    }

    if (data === 'light') {
      return 'light';
    }

    if (data === 'dark') {
      return 'dark';
    }
  }
  return 'system';
};

@Injectable({
  providedIn: 'root',
})
export class ThemeModeService {

  systemMode: ThemeModeType;
  public mode: BehaviorSubject<ThemeModeType> =
    new BehaviorSubject<ThemeModeType>(
      getThemeModeFromLocalStorage(THEME_MODE_LS_KEY, this.platformId)
    );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document) {
    this.systemMode = this.getSystemMode() as 'light' | 'dark';
  }

  public getSystemMode = () => {
    return typeof window !== 'undefined' ? (window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light') : 'light';
  }

  public updateMode(_mode: ThemeModeType) {
    if (isPlatformBrowser(this.platformId)) {
      const updatedMode = _mode === 'system' ? this.systemMode : _mode;
      if (localStorage) {
        localStorage.setItem(THEME_MODE_LS_KEY, updatedMode);
      }
      this.document.documentElement.setAttribute('data-theme', updatedMode);
      this.mode.next(updatedMode);
    }
  }

  public switchMode(_mode: ThemeModeType) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage) {
        const updatedMode = _mode === 'system' ? this.systemMode : _mode;
        localStorage.setItem(THEME_MODE_LS_KEY, updatedMode);
      }
      this.document.location.reload();
    }
  }

  public init(): void {
    const currentTheme = getThemeModeFromLocalStorage(THEME_MODE_LS_KEY, this.platformId);
    this.updateMode(currentTheme);
  }
}

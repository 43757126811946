// Angular
import { Injectable } from "@angular/core";
// RxJs & Lodash
import { find, get } from "lodash";
// Utils
import { CommonService } from '@core/utils';
// Constants
import { ENV_CUSTOMER_COUNTRY_CODE, ENV_CUSTOMER_COUNTRY_ID, ENV_CUSTOMER_COUNTRY_NAME, ENV_CUSTOMER_CURRENCY, ENV_CUSTOMER_LANG, ENV_CUSTOMER_LANG_CODE, ENV_CUSTOMER_LANG_NAME, ENV_CUSTOMER_TYPE, LOCALES } from '@constants';
// Transloco
import { TranslocoService } from "@jsverse/transloco";
import { GeoIPService } from "src/app/core/_base/utils/geo-ip-country.service";
// Environment
import { environment } from "@environment";

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    /**
     * Creates an instance of LayoutService.
     * @param {CommonService} commonService
     * @param {TranslocoService} translocoService
     * @param {GeoIPService} geoIPService
     * @memberof LayoutService
     */
    constructor(
        private readonly commonService: CommonService,
        private readonly translocoService: TranslocoService,
        private readonly geoIPService: GeoIPService,
    ) { }

    /**
     * Set Customer Type
     * @param {string} type
     * @memberof LayoutService
     */
    public setCustomerType(type: string): void {
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_TYPE, type);
    }

    /**
     * Set Customer Language
     * @param {*} language
     * @memberof LayoutService
     */
    public setCustomerLanguage(language: any): void {
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_LANG, language?.locale);
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_LANG_CODE, language?.code);
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_LANG_NAME, language?.language);
    }

    /**
     * Set Customer Country
     * @param {*} country
     * @memberof LayoutService
     */
    public setCustomerCountry(country: any): void {
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_COUNTRY_CODE, country?.code);
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_COUNTRY_ID, country?.id);
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_COUNTRY_NAME, country?.flag);
    }

    /**
     * Set Customer Currency
     * @param {string} currency
     * @memberof LayoutService
     */
    public setCustomerCurrency(currency: string): void {
        this.commonService.setIntoLocalStorage(ENV_CUSTOMER_CURRENCY, currency);
    }

    /**
     * Get Customer Type
     * @returns {string}
     * @memberof LayoutService
     */
    public getCustomerType(): string {
        const customerType = this.commonService.getFromLocalStorage(ENV_CUSTOMER_TYPE);
        return customerType ? customerType : '1';
    }

    /**
     * Get Customer Language
     * @returns {string}
     * @memberof LayoutService
     */
    public getCustomerLanguage(): string {
        const customerLanguage = this.commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);
        return customerLanguage ? customerLanguage : this.translocoService.getActiveLang();
    }

    /**
     * Get Customer Language Object
     * @returns
     * @memberof LayoutService
     */
    public getCustomerLanguageCode() {
        const customerLanguage = this.commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);
        return customerLanguage ? find(LOCALES, (l) => l.locale === customerLanguage) : find(LOCALES, (l) => l.locale === this.translocoService.getActiveLang());
    }

    /**
     * Get Customer Currency
     * @returns {string}
     * @memberof LayoutService
     */
    public getCustomerCurrency(): string {
        const currency = this.commonService.getFromLocalStorage(ENV_CUSTOMER_CURRENCY);
        return currency ? currency : environment.visitorSettings.defaultCurrency;
    }

    /**
     * Get Current Country
     * @returns {Observable<any>}
     * @memberof LayoutService
     */
    public getCurrentCountry() {
        /* Get from Local Storage */
        const countryCode = this.commonService.getFromLocalStorage(ENV_CUSTOMER_COUNTRY_CODE);
        const countryName = this.commonService.getFromLocalStorage(ENV_CUSTOMER_COUNTRY_NAME);
        const countryId = this.commonService.getFromLocalStorage(ENV_CUSTOMER_COUNTRY_ID);
        if (countryId) {
            return {
                code: countryCode,
                id: countryId,
                name: countryName
            };
        }
        else {
            /* Get from GEO IP */
            const result = this.geoIPService.geoIPResponse;
            return this.commonService.getUserLocale(get(result, 'country_code_alpha_2', environment.visitorSettings.defaultLocale));
        }
    }

}
// Angular
import { Injectable } from '@angular/core';
// RxJs
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '@environment';
// Service
import { CommonService } from './common.service';
// Constants
import { APP_CONSTANT } from '@constants';
export const WS_ENDPOINT = environment.wsEndpoint;

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  // Private
  private socket$: WebSocketSubject<any>;

  /**
   * Creates an instance of WebSocketService.
   * @param {CommonService} cs
   * @memberof WebSocketService
   */
  constructor(readonly cs: CommonService) {
  }

  /**
   * Open web socket connection
   * @returns WebSocketSubject
   */
  connect(): WebSocketSubject<any> {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getWebSocket();
    }
    return this.socket$;
  }

  /**
   * Close web socket connection
   */
  close() {
    this.socket$.complete();
  }

  /**
   * Return a custom WebSocket subject
   * @returns WebSocketSubject
   */
  private getWebSocket(): WebSocketSubject<any> {
    const jwt = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
    const webSocketUrl = `${WS_ENDPOINT}token?${jwt}`;
    return webSocket({
      url: webSocketUrl
    });
  }

}

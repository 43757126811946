// Angular
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
// RxJs
import { Observable, of } from 'rxjs';
// Loadsh
import { includes, noop, toString } from 'lodash';
// Sentry
import * as Sentry from '@sentry/angular';
// Environment
import { environment } from '@environment';
// Store
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/core/auth';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    /**
     * Creates an instance of GlobalErrorHandler.
     * @param {Document} document
     * @param {Injector} injector
     * @param {Object} platformId
     * @memberof GlobalErrorHandler
     */
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly injector: Injector,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
    }

    /**
     * handleError : Handle Http operation that failed . Let the app continue.
     * @param operation : name of the operation that failed
     * @param result : optional value to return as the observable result
     * @param isApiError: boolean
     */
    public handleError<T>(operation?: any, error?: any, isApiError: boolean = false): Observable<any> {

        // if no error then return emptry Observable<[]>
        if (!error && !operation) { return of([]); }

        // Handle JWT Expiry or Authorization
        if (error instanceof HttpErrorResponse && error.status === 401) {
            const store = this.injector.get(Store);
            alert('Your session has expired. Please relogin');
            store.dispatch(new Logout());
            return of(false);
        }

        // Construct Error Msg for Sentry and Console Logging
        const errorMsg: string = this.constructErrorMsg(operation, error, isApiError);
        if (environment.isSentryLoggingEnable) {
            // Send the API error to sentry logging infrastructure
            // Skip API 400, 404 & 410 Errors
            const skipLoggingStatusCodes: Array<number> = [400, 404, 410];
            (isApiError && includes(skipLoggingStatusCodes, error?.status)) ? noop() : Sentry.captureMessage(errorMsg);
        } else {
            console.error(errorMsg);
        }

        // Let the app keep running by returning an empty result.
        return of(error);
    }

    /**
     * constructErrorMsg : create custom string error message
     * @param operation : name of the operation that failed
     * @param error: any
     * @param isApiError: {boolean}
     * @returns string
     */
    private constructErrorMsg(operation: any, error: any, isApiError: boolean): string {
        const startMsg: string = isApiError ? 'API ERROR' : 'JS ERROR';
        const source: string = isPlatformBrowser(this.platformId) ? 'BROWSER' : 'SSR';
        let errorMsg = `${startMsg} | ${source} | ${operation}`;

        if (typeof error === 'string') {
            errorMsg += ` | ${error}`;
        }
        if (error && error.stack) {
            errorMsg += ` | ${toString(error?.stack)}`;
        }
        if (error instanceof HttpErrorResponse) {
            errorMsg += ` | ${error?.url} | ${error?.message} | ${error?.error?.message} | ${error?.error?.details}`;
        }
        if (error?.error instanceof Error) {
            errorMsg += ` | ${error?.error?.message}`;
        }
        if (isApiError) {
            errorMsg += ` | ${this.document.location.href}`;
        }

        // Override message incase of application error
        if (operation && operation.type === 'error') {
            errorMsg = `JS | APPLICATION ERROR | ${JSON.stringify(operation, ['message', 'arguments', 'type', 'name', 'currentTarget', 'target'])}`;
            console.log(errorMsg, operation, operation?.target, operation?.currentTarget);
        }

        return errorMsg;
    }
}

import { Action } from '@ngrx/store';
import { IUserAccount } from '../_models/user.model';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    ErrorOccured = '[Login] Auth API'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { user: IUserAccount , authToken: string }) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: IUserAccount }) { }
}

export class ErrorOccured implements Action {
    readonly type = AuthActionTypes.ErrorOccured;
    constructor(public payload: { error: any }) { }
}

export type AuthActions = Login | Logout | UserRequested | UserLoaded | ErrorOccured;

// Angular
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// RxJS
import { Observable, Subject } from 'rxjs';
import { takeUntil, catchError, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/index';
import { isLoggedIn } from '../_selectors/auth.selectors';
// Services
import { GlobalErrorHandler, CommonService, SentryService } from '@core/utils';
import { AuthService } from '../_services';
import { Logout } from '../_actions/auth.actions';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {
  private unsubscribe = new Subject<void>();

  /**
   * AuthGuard constructor
   *
   * @param store: Store<AppState>
   * @param router: Router
   * @param authService: AuthService
   * @param globalErrorHandler: GlobalErrorHandler
   * @param cs: CommonService
   * @param sentryService: SentryService
   */
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly globalErrorHandler: GlobalErrorHandler,
    private readonly cs: CommonService,
    private readonly sentryService: SentryService,
    private readonly translocoService: TranslocoService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.getAuthToken().pipe(
      map((response) => {
        return this.handleResponse(response, state);
      }),
      catchError(error => {
        this.handleUnauthorizedAccess(state);
        return this.globalErrorHandler.handleError('auth-guard', error, true);
      }),
      takeUntil(this.unsubscribe)
    );
  }

  /**
   * On Destroy
   * @summary To avoid memory leak destroy component subscription
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Handle Response After checking user is logged in or not also
   * roles and permissions details
   *
   * @param response: forkJoin array of  two response
   * @returns Observable<boolean>
   */
  private handleResponse(isLogged: any, state: RouterStateSnapshot): Observable<boolean> {
    if (!isLogged) this.handleUnauthorizedAccess(state);
    return isLogged;
  }

  /**
   * Handle un-authorized access
   * @returns Void
   */
  private handleUnauthorizedAccess(state: RouterStateSnapshot): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        this.store.dispatch(new Logout());
        this.cs.clearAll();
        this.sentryService.resetAll();
        const returnUrl = state.url !== '/' ? state.url : undefined;
        const lang = this.translocoService.getActiveLang();
        this.router.navigate([`/${lang}/auth/login`], {
          queryParams: { returnUrl },
        });
      }
    } catch (error) {
      this.globalErrorHandler.handleError('auth-guard-handle-unauthorized-access', error);
    }
  }
}

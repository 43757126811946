// Environment
import { environment } from '@environment';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Lodash
import { split } from 'lodash';
// Recaptcha
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

function recaptchaLocaleId(service: TranslocoService) {
    try {
        const locale = service.getActiveLang();
        return locale ? split(locale, '-')[0] : 'en';
    } catch (error) {
        return 'en';
    }
}
export const RecaptchaLocaleProvider = {
    provide: RECAPTCHA_LANGUAGE,
    useFactory: recaptchaLocaleId,
    deps: [TranslocoService],
};

export const RecaptchaKeyProvider = {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment?.recaptcha?.siteKey } as RecaptchaSettings,
};
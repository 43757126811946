// Angular 
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// Rxjs
import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class LazyLoadScriptService {

    // Public
    loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

    /**
     * Creates an instance of LazyLoadScriptService.
     * @param {Document} document
     * @memberof LazyLoadScriptService
     */
    constructor(@Inject(DOCUMENT) private readonly document: Document) { }

    /**
     * Lazyload the script
     * @param {string} url
     * @param {string} [id]
     * @returns {Observable<void>}
     * @memberof LazyLoadScriptService
     */
    loadScript(url: string, id?: string): Observable<void> {
        if (this.loadedLibraries[url]) {
            return this.loadedLibraries[url].asObservable();
        }

        // Check if script is already loaded
        const scriptElements = this.document.querySelectorAll(`script[src="${url}"]`);
        if (scriptElements.length > 0) {
            this.loadedLibraries[url] = new ReplaySubject();
            this.loadedLibraries[url].next();
            this.loadedLibraries[url].complete();
            return this.loadedLibraries[url].asObservable();
        }

        this.loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.defer = true;
        if (id) script.id = id;
        script.src = url;
        script.onload = () => {
            this.loadedLibraries[url].next();
            this.loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);

        return this.loadedLibraries[url].asObservable();
    }
}
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
// Transloco
import {
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
  provideTransloco
} from '@jsverse/transloco';
// Environment
import { environment } from '../environments/environment';
// Constants
import { APP_CONSTANT } from '@constants';
// RxJs
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  /**
   * Creates an instance of TranslocoHttpLoader.
   * @param {HttpClient} http
   * @memberof TranslocoHttpLoader
   */
  constructor(private http: HttpClient) { }

  /**
   * Get Translation JSON files
   * @param {string} lang
   * @return {*} 
   * @memberof TranslocoHttpLoader
   */
  getTranslation(lang: string) {
    try {
      return this.http.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json`);
    } catch (error) {
      console.log('Error in getTranslation(): ', error);
      return of([]);
    }
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        availableLangs: APP_CONSTANT.AVIALABLE_LANGS,
        defaultLang: APP_CONSTANT.DFAULT_LOCALE,
        fallbackLang: APP_CONSTANT.DFAULT_LOCALE,
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: environment.production,
          allowEmpty: true
        }
      }),
      loader: TranslocoHttpLoader,
    })
  ]
})
export class TranslocoRootModule { }

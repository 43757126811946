// Angular
import { registerLocaleData } from '@angular/common';
// Import all desired locale files
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import localeNb from '@angular/common/locales/nb';
import localeDa from '@angular/common/locales/da';
import localeIs from '@angular/common/locales/is';
import localeFi from '@angular/common/locales/fi';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';

// Register all locale data
registerLocaleData(localeEn);
registerLocaleData(localeDa);
registerLocaleData(localeSv);
registerLocaleData(localeNb);
registerLocaleData(localeIs);
registerLocaleData(localeFi);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);

// Constants
import { APP_CONSTANT } from '@constants';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// RxJS & Lodash
import { indexOf, split, trim } from 'lodash';
import { take } from 'rxjs/operators';

// Load I18n locale before loading application
export function preloadI18nLocale(transloco: TranslocoService, document: Document) {
    return () => {
        try {
            const availableLangs: any = transloco.getAvailableLangs();
            const splitURLLang = trim(split(document.location.pathname, '/')[1]);
            let localeId = (null !== splitURLLang && splitURLLang !== '') ? splitURLLang : APP_CONSTANT.DFAULT_LOCALE;
            if (indexOf(availableLangs, localeId) === -1) {
                localeId = APP_CONSTANT.DFAULT_LOCALE;
            }

            // Load translation file based on locale
            return transloco.load(localeId).pipe(take(1)).subscribe({
                next: () => transloco.setActiveLang(localeId),
                error: (err) => console.log('Error while loading preloadI18nLocale: ', err)
            });
        } catch (error) {
            console.error('Exception In preloadI18nLocale', error);
        }
    }
};